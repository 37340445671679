import React, { useMemo } from 'react';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ServiceDetailsContent from './ServiceDetailsContent';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/1.jpg';
import serviceImg from '../../assets/img/service/1.png';
import DetailsHeader from '../../components/Layout/Header/DetailsHeader';
import SectionTitle from '../../components/Common/SectionTitle';


const RecuritingStaffing = () => {
    const content = useMemo(() => {

        return <SectionTitle
            sectionClass="sec-title4"
            descClass="desc desc-big"
            //description="We donec pulvinar magna id leoersi pellentesque impered dignissim rhoncus euismod euismod eros vitae best consulting & financial services theme . "
            secondDescClass="desc"
            secondDescription={<div>
                <h4>Permanent Staffing</h4>
                <p>We help build productive and successful internal teams through the hiring of full-time candidates with the big-picture goal of employee retention. Our services include senior, executive, and mid-level management hiring needs. We extensively vet candidates to recruit top talent who possess the experience and temperament to lead highly successful initiatives.</p>

                <h4>Temporary Staffing</h4>
                <p>Bizu LLC assists companies to facilitate coverage during peak workloads, staff for finite projects and awarded contracts, and cover short-term absences due to vacation, PTO, and maternity leave. Bizu LLC has a proven record in placing candidates for temporary and contract roles across a broad range of areas.
                </p>

                <h4>Contract-To-Hire</h4>
                <p>Our contract to hire services offer companies a period of evaluation to determine if an employee fits strategically in the long term. Contract to hire positions offers candidates short-term employment with the opportunity to become full-time employees at the end of the contractual period. In many cases, contract-to-hire positions are mutually beneficial to both candidates and employers.
                </p>
            </div>}
        />
    }, [])
    return (
        <React.Fragment>
            <DetailsHeader
                parentMenu='service'
                secondParentMenu='services'
                activeMenu='/recruiting-staffing'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Recruiting & Staffing'
                pageCategory='Services'
                pageName='Recruiting & Staffing'
                parentPath="/service"
            />
            {/* breadcrumb-area-end */}
            <ServiceDetailsContent title="Finding and recruiting top talent for a diverse range of disciplines and industries" subtitle="Recruiting & Staffing" children={content} serviceImg={serviceImg} />
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default RecuritingStaffing;