import React, { useState, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';



//Custom Components

import MainPage from '../pages/main';
import About from '../pages/about';
import Services from '../pages/service';
import ServiceOne from '../pages/service/service-1';
import ServiceTwo from '../pages/service/service-2';
import ServiceDetails from '../pages/service/service-details';
import SoftwareDevelopment from '../pages/service/software-development';
import WebDevelopment from '../pages/service/web-development';
import AnalyticSolutions from '../pages/service/analytic-solutions';
import CloudAndDevops from '../pages/service/cloud-and-devops';
import SystemIntegration from '../pages/service/system-integration';
import RecruitingStaffing from '../pages/service/recruiting-staffing';
import ProjectDesign from '../pages/service/project-design';
import DataCenter from '../pages/service/data-center';
import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'


const App = () => {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        function fakeRequest() {
            return new Promise(resolve => setTimeout(() => resolve(), 2500));
        }
        fakeRequest().then(() => {
            const el = document.querySelector(".loader-container");
            if (el) {
                el.remove();
                setLoading(!isLoading);
            }
        });
    }, [isLoading]);

    if (isLoading) {
        return null;
    }

    return (
        <div className='App'>
            <Router>
                <LoadTop />
                <Switch>
                    <Route path="/" exact component={MainPage} />
                    <Route path="/home" component={MainPage} />
                    <Route path="/about" component={About} />
                    <Route path="/service" exact component={Services} />
                    <Route path="/service/service-1" component={ServiceOne} />
                    <Route path="/service/service-2" component={ServiceTwo} />
                    <Route path="/service/service-details" component={ServiceDetails} />
                    <Route path="/service/software-development" component={SoftwareDevelopment} />
                    <Route path="/service/system-integration" component={SystemIntegration} />
                    <Route path="/service/recruiting-staffing" component={RecruitingStaffing} />
                    <Route path="/service/web-development" component={WebDevelopment} />
                    <Route path="/service/analytic-solutions" component={AnalyticSolutions} />
                    <Route path="/service/cloud-and-devops" component={CloudAndDevops} />
                    <Route path="/service/project-design" component={ProjectDesign} />
                    <Route path="/service/data-center" component={DataCenter} />
                    <Route path="/contact" component={Contact} />
                    <Route component={Error} />
                </Switch>
            </Router>

        </div>
    );
}

export default App;
