import CodesDetail from '../../components/Service/CodesDetail';
import serviceIcon from '../../assets/img/service/style2/hover-img/5.png';

const Codes = (props) => {
    return <div>             
                <div className="rs-codes codes-style pb-30 md-pb-80">
                        <div className="container">
                        <div className="row codes-title pb-20" style={{testAlign:'center', contentAlign:'center'}}>
                            <span>CORPORATE CODES</span>
                        </div>
                            <div className="row">
                                <div className="col-lg-4 md-mb-30">
                                    <CodesDetail
                                        itemClass="codes-item"
                                        style={{"bgColor":'#513574',"codeColor":'#412960'}}
                                        serviceImage={serviceIcon}
                                        Title="DUNS CODE"
                                        Codes={[{number:"110996616",text:'Data Universal Numbering System Code'}]}
                                    />
                                </div>
                                <div className="col-lg-4 md-mb-30">
                                    <CodesDetail
                                        itemClass="codes-item"
                                        style={{"bgColor":'#d06900',"codeColor":'#B55C02'}}
                                        serviceImage={serviceIcon}
                                        Title="CAGE CODE"
                                        Codes={[{number:"96D20",text:'Commercial and Government Entity Code'}]}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <CodesDetail
                                        itemClass="codes-item"
                                        style={{"bgColor":'#59ace5',"codeColor":'#4998CF'}}
                                        serviceImage={serviceIcon}
                                        Title="NACIS CODES"
                                        Codes={[{number:"541511",text:'Custom Computer Programming Services'},{number:'541512',text:'Computer Systems Design Services'},{number:'541513',text:'Computer Facilities Management Services'},{number:'541519',text:'Other Computer Related Services'},{number:'541611',text:'Administrative Management and General Management Consulting Services'},{number:'541618',text:'Other Management Consulting Services'}]}
                                    />
                                </div>
                            </div>
                        </div>
                </div>
            </div>
}

export default Codes;