import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';

import bgImg from '../../assets/img/bg/call-action.jpg';

const bgStyle = {
    backgroundImage: `url(${bgImg})`
}

const CTA = (props) => {
    return (
        <div className="rs-call-action pt-120 pb-120 md-pt-74 md-pb-80" style={bgStyle}>
            <div className="container">
                <SectionTitle
                    sectionClass={props.sectionClass ? props.sectionClass : "sec-title2 text-center"}
                    subtitleClass={props.subtitleClass ? props.subtitleClass : "sub-text white-color"}
                    subtitle={props.subtitle ? props.subtitle : "Let’s Talk"}
                    titleClass={ "title title4 white-color"}
                    title={props.ctaTitle ? props.ctaTitle : "We manage all kinds of IT services that will grow your success"}
                />
                <div className="call-btn text-center mt-30">
                    <Link className="readon discover started" to="/contact">{props.ctaButtonText ? props.ctaButtonText :"Get Started"}</Link>
                </div>
            </div>
        </div>
    );
}

export default CTA;