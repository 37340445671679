import React, { useMemo } from 'react';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ServiceDetailsContent from './ServiceDetailsContent';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/1.jpg';
import serviceImg from '../../assets/img/service/2.png';
import DetailsHeader from '../../components/Layout/Header/DetailsHeader';
import SectionTitle from '../../components/Common/SectionTitle';


const SoftwareDevelopment = () => {
    const content = useMemo(() => {


        return <SectionTitle
            sectionClass="sec-title4"
            descClass="desc desc-big"
            description="Custom applications can give a company a powerful competitive advantage. But designing specialized software internally can be time intensive and costly. "
            secondDescClass="desc"
            secondDescription={<div>
                <p>Custom software design and development comprises the cornerstone of Bizu LLC, a domain in which the company has carved out a successful niche for itself. Bizu LLC provides a complete software implementation process, starting with software design and development, software quality assurance testing, software deployment, up to further software upgrades and enhancements. The core software design and development services provided by Bizu LLC include, but are not limited to, web and desktop application development, high-end client-server application development, enterprise application development, and building end-to-end enterprise application integration (EAI) solutions, re-engineering, software systems maintenance, and support.
                </p>
                <p>Our software development services span the entire software development lifecycle; from requirements definition, design, and application development, to testing, maintenance, and support.
                </p>
            </div>}
        />
    }, [])
    return (
        <React.Fragment>
            <DetailsHeader
                parentMenu='service'
                secondParentMenu='services'
                activeMenu='/software-development'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Software Development'
                pageCategory='Services'
                pageName='Software Development'
                parentPath="/service"
            />
            {/* breadcrumb-area-end */}
            <ServiceDetailsContent title="Systems tailored to the clients’ specific business and technology needs" subtitle="Software Development" children={content} serviceImg={serviceImg} />
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default SoftwareDevelopment;