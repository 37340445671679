import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcess from '../../components/Process/SingleProcess';
import VideoStyleThree from '../../components/Video/VideoStyleThree';


// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage2 from '../../assets/img/process/2.png';
import processImage3 from '../../assets/img/process/3.png';
import processImage4 from '../../assets/img/process/4.png';

import aboutImg from '../../assets/img/about/3.png';
import danceEffectImg from '../../assets/img/about/effect-1.png';


import effectImg1 from '../../assets/img/about/dotted-3.png';
import effectImg2 from '../../assets/img/about/shape3.png';
import CTA from '../sections/CtaSection';

const AboutMain = () => {

    // const options = {
    //     items: 3,
    //     nav: false,
    //     dots: true,
    //     margin: 30,
    //     rewind: false,
    //     autoplay: false,
    //     stagePadding: 30,
    //     navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    //     loop: true,
    //     center: false,
    //     responsive: {
    //         0: {
    //             stagePadding: 0,
    //             items: 1,
    //             dots: false,
    //         },
    //         768: {
    //             items: 2,
    //             stagePadding: 0,
    //             dots: true,
    //         },
    //         992: {
    //             items: 3,
    //             stagePadding: 0,
    //         },
    //         1500: {
    //             items: 3,
    //             stagePadding: 0,
    //         }
    //     }
    // };

    return (
        <React.Fragment>
            {/* about-area-start */}
            <div id="rs-about" className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 md-mb-30">
                            <div className="rs-animation-shape">
                                <div className="images">
                                    <img src={aboutImg} alt="" />
                                </div>
                                <div className="middle-image2">
                                    <img className="dance" src={danceEffectImg} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-60 md-pl-14">
                            <div className="contact-wrap">
                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="About Us"
                                    titleClass="title pb-36"
                                    title="We Are Increasing Business Success With Technology"
                                    descClass="desc pb-34"
                                    secondDescClass="margin-0 pb-16"
                                    secondDescription="Bizu LLC is a highly innovative software development, systems integrator, and technology provider company. We are established to provide leading-edge intelligent technical solutions and consulting services to businesses, organizations, and government offices in order to allow efficient and effective secure access and communication with various heterogeneous information resources and services, anytime and anywhere."
                                />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon learn-more" to="/contact">Learn-More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg1} alt="" />
                        <img className="bottom dance" src={effectImg2} alt="" />
                    </div>
                </div>
            </div>
            {/* about-area-end */}
              {/* breadcrumb-area-start */}

                {/* newsletter-area-start */}
                <CTA
                    ctaSectionClass="rs-cta style1 cta-bg1 pt-70 pb-70"
                    ctaTitleClass="epx-title"
                    ctaTitle="Step forward to become one of the many successful clients of @Bizu and get in the front line for the most wonderful presentation."
                    ctaButtonClass="readon learn-more"
                    ctaButtonLink="#"
                    ctaButtonText="Get In Touch"
                />
                {/* newsletter-area-end */}
            {/* working-process-area-start */}
            <div className="rs-process style2 pt-120 pb-112 md-pt-80 md-pb-72">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title2"
                        title="Our Working Process"
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discovery"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage2}
                                titleClass="title"
                                Title="Planning"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage3}
                                titleClass="title"
                                Title="Execute"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage4}
                                titleClass="title"
                                Title="Deliver"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}

            {/* VideoStyleThree area start */}
            <div class="rs-contact-wrap bg7 pt-120 pb-390 md-pt-80">
                <div class="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg white-color"
                        subtitle="Contact"
                        titleClass="title white-color"
                        title="Request A Free Consultation"
                    />
                </div>
            </div>
            <VideoStyleThree />
            {/* VideoStyleThree area end */}

        </React.Fragment>
    )
}

export default AboutMain;