import React, { useCallback, useRef } from 'react';
import emailjs from 'emailjs-com';

const ContactForm = (props) => {
    const { submitBtnClass } = props;
    const form = useRef();
    const sendEmail = useCallback((e) => {

        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_x0no5hj', 'template_6n0k5cx', form.current, 'user_p9kAyVDEXKiKRjRF4lsZH')
            .then((result) => {
                console.log(result.text);
                window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }, [])

    return (
        <form ref={form} id="contact-form" onSubmit={sendEmail}>
            <div className="row">
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="name" name="user_name" placeholder="Name" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="email" name="user_email" placeholder="E-Mail" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="phone" name="user_phone" placeholder="Phone Number" required />
                </div>

                <div className="col-12 mb-30">
                    <textarea className="from-control" id="message" name="message" placeholder="Your message Here" required></textarea>
                </div>
            </div>
            <div className="btn-part">
                <input className={submitBtnClass ? submitBtnClass : 'readon learn-more submit'} type="submit"/>
            </div>
        </form>
    );

}

export default ContactForm;