import React from 'react';
import CTA from '../sections/CtaSection';
import About from '../sections/AboutSection';
import Process from '../sections/ProcessSection';
import Service from '../sections/ServiceSection';
import Technology from '../sections/TechnologySection';
import Brand from '../../components/Common/Brand';
import BrandThree from '../../components/Common/Brand/BrandThree';
import ToolsAndTechnologies from '../../components/Common/Brand/ToolsAndTechnologies';
import ScrollToTop from '../../components/Common/ScrollTop';
import BannerHome from '../../components/Banner/BannerHome';
import OnepageContactPart from '../../components/Contact/OnepageContact';
import Codes from '../../components/Codes/Codes';

const MainPage = () => {
	return (
		<React.Fragment>
			{/* banner-start */}
			<BannerHome />
			{/* banner-start */}
			{/* Service-area-start */}
			<Service />
			{/* Service-area-end */}
			{/* Codes-start */}
			<Codes/>
			{/* Codes-end */}
			{/* brand-area-start */}
			<BrandThree />
			{/* brand-area-end */}
			{/* About-area-start */}
			<About />
			{/* About-area-end */}
			{/* CTA-start */}
			<CTA />
			{/* CTA-end */}
	
			{/* Process-start */}
			<Process />
			{/* Process-end */}
			{/* Technology-start */}
			<Technology />
			{/* Technology-end */}
			{/* tools-and-tech-start */}
			<ToolsAndTechnologies />
			{/* tools-and-tech-end */}
			{/* Contact-area-start */}
			<OnepageContactPart
				contactSectionClass="rs-contact gray-bg pt-120 md-pt-80"
			/>
			{/* Contact-area-end */}

			{/* brand-area-start */}
			<Brand />
			{/* brand-area-end */}

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default MainPage;