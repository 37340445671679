import React, { useMemo } from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ServiceDetailsContent from './ServiceDetailsContent';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/4.jpg';
import serviceImg from '../../assets/img/service/1.png';
import DetailsHeader from '../../components/Layout/Header/DetailsHeader';


const SyetemIntegration = () => {
    const content = useMemo(() => {

        return <SectionTitle
            sectionClass="sec-title4"
            descClass="desc desc-big"
            description="Bizu LLC offers comprehensive third-party integration services to help clients enhance efficiency at their organizations while saving time and money. Our software application integration services include planning and assessment, implementation, integration, configuration, and customization of a variety of third-party software tools. We deliver seamless integration with third-party vendor tools and software into its clients’ existing software to deliver business value. "
            secondDescClass="desc"
            secondDescription={<div>
                <p>In today’s connected world, the role of system integration engineers is becoming increasingly important: more and more systems offer services via an Application Programming Interface (API) that allow other applications to consume them, offering other services on their own, and so forth. A software system is an aggregation of subsystems cooperating so that the overall one is able to deliver new functionality. In order to support common business processes and data sharing across applications, these applications need to be integrated. Application integration needs to provide efficient, reliable, and secure data exchange between multiple enterprise applications. Effective integration also requires central monitoring and management of potentially hundreds of systems and components, plus the ability to deploy changes on a global scale. Integration solutions present unique challenges due to their sheer size as well the number of layers they span – from high-level business process management down to network packet routing and systems management.</p>
                <p>System integration involves integrating existing, often distributed systems. It is about adding value to the system, with capabilities that are possible because of interactions between subsystems. Integration has a hidden benefit, stemming from trying to make services interact: instead of having to redesign a piece of software from the beginning (which encapsulates particular business logic and so on), you are trying to expose its functionality as a service and let other applications access it via a communications method (either network or process invocation). This allows the re-usability of a software component, without having to re-implement it.</p>
            </div>}
        />
    }, [])

    return (
        <React.Fragment>
            <DetailsHeader
                parentMenu='service'
                secondParentMenu='services'
                activeMenu='/system-integration'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='System Integration & Consulting'
                pageCategory='Services'
                pageName='System Integration & Consulting'
                parentPath="/service"
            />
            {/* breadcrumb-area-end */}
            <ServiceDetailsContent title="Seamless integration with third-party vendor tools and software" subtitle="System Integration" children={content} serviceImg={serviceImg}/>
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default SyetemIntegration;