/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';

const BannerHome = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    const navPrevIcon = () => ("<i class='fa fa-angle-left'></i>");
    const navNextIcon = () => ("<i class='fa fa-angle-right'></i>");


    const options = {
        items: 1,
        nav: false,
        dots: false,
        margin: 0,
        rewind: false,
        autoplay: true,
        autoplayTimeout: 8000,
        stagePadding: 0,
        loop: true,
        center: true,
        navText: [navPrevIcon, navNextIcon],
        responsive: {
            200: {
                nav: true,
            },
            500: {
                nav: true,
            },
        }
    };
    return (
        <React.Fragment>
            {/* <!-- banner section start --> */}
            <div className="rs-banner style4" >
                <ModalVideo channel='youtube' isOpen={isOpen} videoId='YLN1Argi7ik' onClose={() => { openModal(); }} />
                <div className="container">
                    <OwlCarousel options={options} >
                        <div className="slider-content slide1">
                            <div className="container">
                                <div className="content-part">
                                    <div className="banner-content">
                                        <span className="sub-title">Technology &amp; It Solutions </span>
                                        <h1 className="title">Digital Technology Solutions &amp; Services</h1>
                                        <p className="desc">
                                            Highly innovative software house, systems integrator and technology provider
                                        </p>
                                        <ul className="banner-btn">
                                            <li><Link className="readon discover" to="/about">Discover More</Link></li>
                                            <li>
                                                <div className="rs-videos">
                                                    <div className="animate-border white-color">
                                                        <a className="popup-border popup-videos" onClick={() => { openModal(); }}>
                                                            <i className="fa fa-play"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slider-content slide2">
                            <div className="container">
                                <div className="content-part">
                                    <div className="banner-content">
                                        <span className="sub-title">Technology &amp; It Solutions </span>
                                        <h1 className="title">Excellent IT services for your success</h1>
                                        <p className="desc">
                                            Established to provide leading edge intelligent technical solutions and consulting services
                                        </p>
                                        <ul className="banner-btn">
                                            <li><Link className="readon discover" to="/about">Discover More</Link></li>
                                            <li>
                                                <div className="rs-videos">
                                                    <div className="animate-border white-color">
                                                        <div className="address-item mb-26">
                                                            <div className="address-icon">
                                                                <a href="tel:+1(304)4538750">   <i className="fa fa-phone"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider-content slide2">
                            <div className="container">
                                <div className="content-part">
                                    <div className="banner-content">
                                        <span className="sub-title">RECRUITING &amp; STAFFING SOLUTIONS </span>
                                        <h1 className="title">Recruiting &amp; Staffing Solutions</h1>
                                        <p className="desc">
                                            Helping our customers to put the right person in the right job at the right time. Find out how we can help you with your hiring needs.
                                        </p>
                                        <ul className="banner-btn">
                                            <li><Link className="readon discover" to="/about">Discover More</Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider-content slide2">
                            <div className="container">
                                <div className="content-part">
                                    <div className="banner-content">
                                        <span className="sub-title">CLOUD &amp; DEVOPS </span>
                                        <h1 className="title">Cloud Computing Competence</h1>
                                        <p className="desc">
                                            Agile and Tailored Cloud Solutions For Every Business Type To Meet All The Requirements
                                        </p>
                                        <ul className="banner-btn">
                                            <li><Link className="readon discover" to="/about">Discover More</Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </OwlCarousel>

                </div>
            </div>
            {/* <!-- banner section end --> */}

        </React.Fragment>
    );
}

export default BannerHome;