import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';

// Service Image Icons
import roundImg from '../../assets/img/about/round.png';
import aboutImg from '../../assets/img/about/about1.png';

const About = () => {

    return (
        <div id="rs-about" className="rs-about style3 pt-120 pb-120 md-pt-74 md-pb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <SectionTitle
                            sectionClass="sec-title mb-30"
                            subtitleClass="sub-text style2"
                            subtitle="About Us"
                            titleClass="title pb-38"
                            title="We Are Increasing Business Success With Technology"
                            descClass="desc pb-36"
                            description="Bizu LLC is a highly innovative software development, systems integrator, and technology provider company. We are established to provide leading-edge intelligent technical solutions and consulting services to businesses, organizations, and government offices in order to allow efficient and effective secure access and communication with various heterogeneous information resources and services, anytime and anywhere."

                        />
                        <div className="btn-part mt-40">
                            <Link className="readon discover more" to="/about">Learn More</Link>
                        </div>
                    </div>
                    <div className="col-lg-7 md-pt-50">
                        <div className="rs-animation-image">
                            <div className="pattern-img">
                                <img
                                    src={roundImg}
                                    alt=""
                                />
                            </div>
                            <div className="middle-img hidden-md">
                                <img
                                    className="dance3"
                                    src={aboutImg}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;