import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/img/logo/logo.png';

const TopHeader = () => {

    return (
        <div className="topbar-area hidden-md">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2">
                        <div className="logo-part">
                            <Link to="/"><img src={Logo} alt="Logo" /></Link>
                        </div>
                    </div>
                    <div className="col-lg-10 text-right">
                        <ul className="rs-contact-info">
                            <li className="contact-part">
                                <i className="flaticon-location"></i>
                                <span className="contact-info">
                                    <span>Address</span>
                                    <div>601 Avery Street, 26101, Parkersburg, WV, USA</div>
                                    <div>1730 Rhode Island Avenue, N.W., Suite 1005, Washington D.C. 20036</div>
                                </span>
                            </li>
                            <li className="contact-part">
                                <i className="flaticon-email"></i>
                                <span className="contact-info">
                                    <span>E-mail</span>
                                    <div><a href="mailto:support@bizu.llc"> support@bizu.llc </a></div>
                                    <div><a href="mailto:support@bizu.llc"> contact@bizu.llc </a></div>
                                </span>
                            </li>
                            <li className="contact-part">
                                <i className="flaticon-call"></i>
                                <span className="contact-info">
                                    <span>Phone</span>
                                    <div>+1(304)4538750</div>
                                    <div>+1(202)8492903</div>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopHeader;