import React, { useMemo } from 'react';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ServiceDetailsContent from './ServiceDetailsContent';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/3.jpg';
import serviceImg from '../../assets/img/service/4.png';

import DetailsHeader from '../../components/Layout/Header/DetailsHeader';
import SectionTitle from '../../components/Common/SectionTitle';


const AnalyticSolutions = () => {

    const content = useMemo(() => {

        return <SectionTitle
            sectionClass="sec-title4"
            descClass="desc desc-big"
            description="The sheer amount and variety of existing and new data generated in the world today are unprecedented. As this growth continues, so do the opportunities for organizations to use their data to create Change that Matters. Bizu LLC helps clients identify and capture the most value and meaningful insights from data, and turn them into competitive advantages."
            secondDescClass="desc"
            //secondDescription="Business ipsum dolor sit amet nsectetur cing elit. Suspe ndisse suscipit sagittis leo sit met entum is not estibulum dignity sim posuere cubilia durae. Leo sit met entum cubilia crae. At vero eos accusamus et iusto odio dignissimos provident nam libero tempore, cum soluta."
        />
    }, [])

    return (
        <React.Fragment>
            <DetailsHeader
                parentMenu='service'
                secondParentMenu='services'
                activeMenu='/analytic-solutions'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Analytics Solutions'
                pageCategory='Services'
                pageName='Analytics Solutions'
                parentPath="/service"
            />
            {/* breadcrumb-area-end */}
            <ServiceDetailsContent title="We help clients become analytics-driven organizations that achieve better performance through data." subtitle="Analytics Solutions" children={content} serviceImg={serviceImg}/>
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default AnalyticSolutions;