import React from 'react';
import DetailsHeader from '../../components/Layout/Header/DetailsHeader';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import AboutMain from './AboutMain';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/bg/bg-3.jpg';

const About = () => {

    return (
        <React.Fragment>
            <DetailsHeader
                parentMenu='about'
                activeMenu="/about"
            />
            <main>
                {/* breadcrumb-area-start */}
                <SiteBreadcrumb
                    pageTitle="About"
                    titleClass="page-title"
                    pageName="About"
                    breadcrumbsImg={bannerbg}
                />
                {/* breadcrumb-area-start */}

                {/* About Main */}
                <AboutMain />
                {/* About Main */}

                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}
            </main>
            <Footer />
            <SearchModal />
        </React.Fragment>
    );
}

export default About;