import React,{useMemo} from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ServiceDetailsContent from './ServiceDetailsContent';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/2.jpg';
import serviceImg from '../../assets/img/service/3.png';
import DetailsHeader from '../../components/Layout/Header/DetailsHeader';



const WebDevelopment = () => {
    const content = useMemo(() => {

        return <SectionTitle
            sectionClass="sec-title4"
            descClass="desc desc-big"
            description="Design a truly innovative, intuitive, and beautiful front end that speaks to your customers and helps them engage with your website or application."
            secondDescClass="desc"
            //secondDescription="Business ipsum dolor sit amet nsectetur cing elit. Suspe ndisse suscipit sagittis leo sit met entum is not estibulum dignity sim posuere cubilia durae. Leo sit met entum cubilia crae. At vero eos accusamus et iusto odio dignissimos provident nam libero tempore, cum soluta."
        />
    }, [])
    return (
        <React.Fragment>
            <DetailsHeader
                parentMenu='service'
                secondParentMenu='services'
                activeMenu='/web-development'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Web Development'
                pageCategory='Services'
                pageName='Web Development'
                parentPath="/service"
            />
            {/* breadcrumb-area-end */}
            <ServiceDetailsContent title="Engaging Front End" subtitle="Web Development" children={content} serviceImg={serviceImg}/>
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default WebDevelopment;