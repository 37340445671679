/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const DetailsMenuItems = (props) => {
    const { menuItem1, menuItem2, menuItem3, menuItem7, activeMenu } = props;

    const [home, setHome] = useState(true)
    const [about, setAbout] = useState(false)
    const [service, setService] = useState(false)
    const [portfolio, setPortfolio] = useState(false)
    const [team, setTeam] = useState(false)
    const [blog, setBlog] = useState(false)
    const [contact, setContact] = useState(false)

    const addActiveClass = (menu) => {

        if (menu === 'home') {
            setHome(!home)
            setAbout(false)
            setService(false)
            setPortfolio(false)
            setTeam(false)
            setBlog(false)
            setContact(false)
        }
        else if (menu === 'about') {
            setHome(false)
            setAbout(!home)
            setService(false)
            setPortfolio(false)
            setTeam(false)
            setBlog(false)
            setContact(false)
        }
        else if (menu === 'service') {
            setHome(false)
            setAbout(false)
            setService(!home)
            setPortfolio(false)
            setTeam(false)
            setBlog(false)
            setContact(false)
        }
        else if (menu === 'portfolio') {
            setHome(false)
            setAbout(false)
            setService(false)
            setPortfolio(!home)
            setTeam(false)
            setBlog(false)
            setContact(false)
        }
        else if (menu === 'team') {
            setHome(false)
            setAbout(false)
            setService(false)
            setPortfolio(false)
            setTeam(!home)
            setBlog(false)
            setContact(false)
        }
        else if (menu === 'blog') {
            setHome(false)
            setAbout(false)
            setService(false)
            setPortfolio(false)
            setTeam(false)
            setBlog(!home)
            setContact(false)
        }
        else if (menu === 'contact') {
            setHome(false)
            setAbout(false)
            setService(false)
            setPortfolio(false)
            setTeam(false)
            setBlog(false)
            setContact(!home)
        }
    };

    return (
        <React.Fragment>
            {
                menuItem1 ?
                    <li className={home ? "current-menu-item" : ""}>
                        <Link to="/home" className={activeMenu === "/home" ? "active-menu" : ""}>Home</Link>
                    </li>
                    : ''
            }
            {
                menuItem2 ?
                    <li className={home ? "current-menu-item" : ""}>
                        <Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
                    </li>
                    : ''
            }

            {
                menuItem3 ?
                    <li className={home ? "current-menu-item" : ""}>
                        <Link to="/service" className={activeMenu === "/service" ? "active-menu" : ""}>Services</Link>
                    </li>
                    : ''
            }

            {
                menuItem7 ?
                    <li className={home ? "current-menu-item" : ""}>
                        <Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>Contact</Link>
                    </li>
                    : ''
            }

        </React.Fragment>
    );
}

export default DetailsMenuItems;