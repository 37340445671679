import React, { useMemo } from 'react';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ServiceDetailsContent from './ServiceDetailsContent';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/4.jpg';
import serviceImg from '../../assets/img/service/3.png';
import DetailsHeader from '../../components/Layout/Header/DetailsHeader';
import SectionTitle from '../../components/Common/SectionTitle';

const CloudAndDevops = () => {

    const content = useMemo(() => {
        return <SectionTitle
            sectionClass="sec-title4"
            descClass="desc desc-big"
            secondDescription="It’s hard to overstate the impact of cloud computing. What was once an intriguing technological option is now an essential element of digital transformation. And with experts forecasting continued growth—to the tune of 21% annually through 2024—there’s no sign of a slow-down.
            But just because “the cloud” has become a part of the everyday conversation doesn’t mean everyone understands it well. Our cloud consultants have worked with many companies, across many industries, to integrate private and hybrid cloud opportunities into product development and go-to-market strategies, infrastructure, M&A opportunities, investment decisions, and more.
            "
            secondDescClass="desc"
        description="We are the ideal partner for start-ups, medium-sized businesses and large enterprises when it comes to digital challenges in the complex IT area and optimal cloud solutions."
        />
    }, [])

    return (
        <React.Fragment>
            <DetailsHeader
                parentMenu='service'
                secondParentMenu='services'
                activeMenu='/cloud-and-devops'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Cloud & Devops'
                pageCategory='Services'
                pageName='Cloud & Devops'
                parentPath="/service"
            />
            {/* breadcrumb-area-end */}
            <ServiceDetailsContent title="We accelerate your entry into the Cloud." subtitle="Cloud & DevOps" children={content} serviceImg={serviceImg} />
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default CloudAndDevops;