import React, { useMemo } from 'react';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ServiceDetailsContent from './ServiceDetailsContent';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/2.jpg';
import DetailsHeader from '../../components/Layout/Header/DetailsHeader';
import SectionTitle from '../../components/Common/SectionTitle';


const ServiceDetails = () => {
    const content = useMemo(() => {

        return <SectionTitle
            sectionClass="sec-title4"
            descClass="desc desc-big"
            description="We donec pulvinar magna id leoersi pellentesque impered dignissim rhoncus euismod euismod eros vitae best consulting & financial services theme . "
            secondDescClass="desc"
            secondDescription="Business ipsum dolor sit amet nsectetur cing elit. Suspe ndisse suscipit sagittis leo sit met entum is not estibulum dignity sim posuere cubilia durae. Leo sit met entum cubilia crae. At vero eos accusamus et iusto odio dignissimos provident nam libero tempore, cum soluta."
        />
    }, [])
    return (
        <React.Fragment>
            <DetailsHeader
                parentMenu='service'
                secondParentMenu='services'
                activeMenu='/service-details'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Software Development'
                pageCategory='Services'
                pageName='Software Development'
            />
            {/* breadcrumb-area-end */}

            <ServiceDetailsContent  subtitle="Software Development" children={content} />

            <Footer footerClass="rs-footer" />

            <SearchModal />
        </React.Fragment>
    );
}

export default ServiceDetails;