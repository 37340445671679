import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/work-bg.png';

const bgStyle = {
    backgroundImage:`url(${bgImg})`
}

const Process = () => {

    return (
        <div className="rs-process style3 gray-bg pt-120 pb-120 md-pt-74 md-pb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="process-wrap home7" style={bgStyle}>
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text new"
                                subtitle="How We Work"
                                titleClass="title title4 white-color pb-20"
                                title="How Bizu LLC assist your business"
                                descClass="desc white-color"
                                description="We ensure that our solution perfectly matches clients’ needs, values, and business priorities."
                            />
                            <div className="btn-part mt-40">
                                <Link className="readon discover started" to="/about">Learn More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 pl-35 md-pt-40 md-pl-15">
                        <div className="row">
                            <div className="col-md-6 mb-20">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="01"
                                    Title="Product Discovery Phase"
                                    Text="We conduct detailed project analysis, user research, and market exploration, allowing us to define the scope of work and your project’s goals and restrictions. We also develop a solution vision."
                                />
                            </div>
                            <div className="col-md-6 mb-20">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="02"
                                    Title="Product Design Phase"
                                    Text="We create product interfaces that help end users effortlessly meet their needs and help you easily achieve your goals and grow your business."
                                />
                            </div>
                            <div className="col-md-6 md-mb-20">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="03"
                                    Title="Product Engineering Phase"
                                    Text="We develop custom software based on your individual needs, requirements, and business specifications as determined during the product discovery phase."
                                />
                            </div>
                            <div className="col-md-6">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="04"
                                    Title="Maintenance and Support"
                                    Text="We offer continuous software maintenance and enhancements to keep your online presence in line with modern trends and industry demands."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Process;