/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import CTA from '../../components/Common/CTA';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceFlip from '../../components/Service/SingleServiceFlip';
import SingleProcess from '../../components/Common/Process';
import VideoStyleTwo from '../../components/Video/VideoStyleTwo';

// Service Icons
import serviceIcon1 from '../../assets/img/service/main-home/1.png';
import serviceIcon2 from '../../assets/img/service/main-home/2.png';
import serviceIcon3 from '../../assets/img/service/main-home/3.png';
import serviceIcon4 from '../../assets/img/service/main-home/4.png';
import serviceIcon5 from '../../assets/img/service/main-home/5.png';
import serviceIcon6 from '../../assets/img/service/main-home/6.png';

const ServiceOneMain = () => {
    return (
        <React.Fragment>
            {/* services-area-start */}
            <div className="rs-services style2 pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 offset-lg-4">
                            <h2>Services We Deliver </h2>
                            <h4>we provide truly prominent solutions.</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon1}
                                Title="Software Development"
                                TextDesc="Bizu LLC specializes in developing custom software applications that meet the specific needs of its clients and drive their business processes. Combining in-depth technical and industry experience, We develop innovative custom software application solutions that best meet clients’ most complex business needs, budget, schedule, and existing IT infrastructure."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                                serviceURL="software-development"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon2}
                                Title="Web Development"
                                TextDesc="Create an outstanding web presence for an ever-changing digital landscape with Bizu LLC. A client-focused approach, unparalleled technological expertise, and transparent collaboration enable us to deliver incredible outcomes and results-driven solutions for our clients."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                                serviceURL="web-development"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon3}
                                Title="Analytic Solutions"
                                TextDesc="We provide unparalleled value-optimizing Information Insight to client organizations through the use of cutting-edge, customized reporting systems, analytical models, and database applications."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                                serviceURL="analytic-solutions"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 md-mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon4}
                                Title="Cloud and DevOps"
                                TextDesc="DevOps & Cloud computing consulting & infrastructure services provider offering expertise to create a robust application. Bizu LLC offers expertise in Amazon Web Services and Google Cloud Platform for reliable, scalable, and cost-effective cloud computing services. Our AWS and GCP expertise allows us to provide the flexibility to launch applications irrespective of your industry or magnitude of business."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                                serviceURL="cloud-and-devops"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 sm-mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon5}
                                Title="System Integration & Consulting"
                                TextDesc="We deliver seamless integration with third-party vendor tools and software into our clients’ existing software to deliver business value. We are committed to provide high-end consulting services and solutions to meet our clients’ unique business challenges."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                                serviceURL="system-integration"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon6}
                                Title="Recruiting & Staffing"
                                TextDesc="Bizu LLC works as a strategic partner to assist in the hiring needs for IT, finance, creative, and administrative positions across the country. We provide human resources solutions, executive and employee recruiting services, temporary staffing, managed staffing and outsourcing services."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                                serviceURL="recruiting-staffing"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* services-area-end */}

            {/* process-area-start */}
            <div className="rs-process style5 bg5 pt-120 pb-120 md-pt-80 md-pb-64">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 md-mb-40">
                            <div className="process-wrap">
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text new"
                                    subtitle="Working Process"
                                    titleClass="title white-color"
                                    title="Our Working Process -  How We Work For Our Customers"
                                />
                                <div className="btn-part mt-40">
                                    <Link to="/contact">
                                        <a className="readon learn-more contact-us">Contact Us</a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 pl-30 md-pl-15">
                            <div className="row">
                                <div className="col-md-6 mb-40">
                                    <SingleProcess
                                        processNumber="1"
                                        processTitle="Discovery"
                                        processText="We meet customers in set place to discuss the details about needs and demands before proposing a plan."
                                    />
                                </div>
                                <div className="col-md-6 mb-40">
                                    <SingleProcess
                                        processNumber="2"
                                        processTitle="Concepts & Initatives"
                                        processText="Our experts come up with all kinds of ideas and initiatives for delivering the best solutions for IT services chosen."
                                    />
                                </div>
                                <div className="col-md-6 sm-mb-40">
                                    <SingleProcess
                                        processNumber="3"
                                        processTitle="Testing & Trying"
                                        processText="After agreeing on the ideas and plans, we will conduct as scheduled and give comments on the results & adaptations."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <SingleProcess
                                        processNumber="4"
                                        processTitle="Execute & install"
                                        processText="Once the final plan is approved, everything will be conducted according to the agreed contract."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* process-area-End */}

            {/* Contact Form Start */}
            <VideoStyleTwo />
            {/* Contact Form End */}

            {/* newsletter-area-start */}
            <CTA
                ctaSectionClass="rs-cta style1 cta-bg1 pt-70 pb-70"
                ctaTitleClass="epx-title"
                ctaTitle="Grow Your Business and Build Your Website or Software With us."//Team up with the best collaboration from the right expertises. 
                ctaButtonClass="readon learn-more"
                ctaButtonLink="#"
                ctaButtonText="Get In Touch"
            />
            {/* newsletter-area-end */}
        </React.Fragment>
    );
}

export default ServiceOneMain;