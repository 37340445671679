import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import hoverIcon1 from '../../assets/img/service/style2/hover-img/1.png';
import mainIcon2 from '../../assets/img/service/style2/main-img/2.png';
import hoverIcon2 from '../../assets/img/service/style2/hover-img/2.png';
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import hoverIcon3 from '../../assets/img/service/style2/hover-img/3.png';
import mainIcon4 from '../../assets/img/service/style2/main-img/4.png';
import hoverIcon4 from '../../assets/img/service/style2/hover-img/4.png';
import mainIcon5 from '../../assets/img/service/style2/main-img/5.png';
import hoverIcon5 from '../../assets/img/service/style2/hover-img/5.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import hoverIcon6 from '../../assets/img/service/style2/hover-img/6.png';

const Service = () => {

    return (
        <div id="rs-service" className="rs-services style3 pt-110 pb-100 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text"
                    subtitle="Services"
                    titleClass="title testi-title"
                    title="Our Featured Services"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={hoverIcon1}
                            Title="Software Development"
                            serviceURL="software-development"
                            Text="We provide a complete software implementation process, starting with software design and development, software quality assurance testing, software deployment, up to further software upgrades and enhancements."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon2}
                            hoverIcon={hoverIcon2}
                            Title="Web Development"
                            serviceURL="web-development"
                            Text="Create an outstanding web presence for an ever-changing digital landscape with Bizu LLC. A client-focused approach, unparalleled technological expertise, and transparent collaboration enable us to deliver incredible outcomes and results-driven solutions for our clients."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon3}
                            hoverIcon={hoverIcon3}
                            Title="Analytic Solutions"
                            serviceURL="analytic-solutions"
                            Text="We provide unparalleled value-optimizing  Information Insight to client organizations through the use of cutting-edge, customized reporting systems, analytical models, and database applications."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item paste-bg"
                            prefix="04"
                            mainIcon={mainIcon4}
                            hoverIcon={hoverIcon4}
                            Title="Cloud & DevOps"
                            serviceURL="cloud-and-devops"
                            Text="DevOps & Cloud computing consulting & infrastructure services provider offering expertise to create a robust application.

                            Bizu LLC offers expertise in Amazon Web Services and Google Cloud Platform for reliable, scalable, and cost-effective cloud computing services. Our AWS and GCP expertise allows us to provide the flexibility to launch applications irrespective of your industry or magnitude of business.
                            "
                        />
                    </div>

                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item green-bg"
                            prefix="06"
                            mainIcon={mainIcon6}
                            hoverIcon={hoverIcon6}
                            Title="System Integration & Consulting"
                            serviceURL="system-integration"
                            Text="We deliver seamless integration with third-party vendor tools and software into our clients’ existing software to deliver business value. We are committed to provide high-end consulting services and solutions to meet our clients’ unique business challenges."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item purple-bg"
                            prefix="05"
                            mainIcon={mainIcon5}
                            hoverIcon={hoverIcon5}
                            Title="Recruiting & Staffing"
                            serviceURL="recruiting-staffing"
                            Text="Bizu LLC works as a strategic partner to assist in the hiring needs for IT, finance, creative, and administrative positions across the country. We provide human resources solutions, executive and employee recruiting services, temporary staffing, managed staffing and outsourcing services."
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;