import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

const CodesDetail = (props) => {
	return(
        <div className={props.itemClass} style={{height:"100%", backgroundColor:props.style?.bgColor}}>
            <div className="codes-icon">
                <div className="image-part">
                    <img 
                        src={props.serviceImage} 
                        alt={props.Title}
                    />
                </div>
            </div>
            <div className={props.shapeCall}>{props.shapeImg}</div>
            <div className="codes-content">
                <div className="codes-text">
                    <h3 className="codes-title">
                        {props.Title}
                    </h3>
                </div>
                <div className="codes-desc">
                    {props.Codes.map(code =>
                      <Tooltip key={code} placement="top" trigger={['hover']} overlay={<span style={{fontSize:'1.2em'}}>{code.text}</span>}>
                        <span  style={{
                            cursor: 'pointer',
                            display:'inline-block',
                            fontWeight:'600', 
                            margin:'3px',
                            padding:'5px', 
                            borderRadius:'3px', 
                            backgroundColor:props.style?.codeColor, 
                            color:'white'}}>
                            {code.number}
                        </span>
                    </Tooltip>

                    )
                    }
                </div>
            </div>
        </div>
	)
}

export default CodesDetail