/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';

import footerLogo1 from '../../../assets/img/logo/logo.png';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4">
                            <div className="footer-logo">
                                <Link to="/" ><img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /></Link>
                            </div>
                            <div className="textwidget pb-30">
                                <p>We ensure that our solution perfectly matches clients’ needs, values, and business priorities.</p>
                            </div>
                            <ul className="footer-social md-mb-30">
										<li>
											<a href="https://www.facebook.com/bizullc" target="_blank" ><i className="fa fa-facebook-f"></i></a>
										</li>
										<li>
											<a href="https://twitter.com/bizu_llc" target="_blank"><i className="fa fa-twitter"></i></a>
										</li>
										<li>
											<a href="https://instagram.com/bizu_llc" target="_blank"><i className="fa fa-instagram"></i></a>
										</li>
									</ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
                            <h3 className="widget-title">IT Services</h3>
                            <ul className="site-map">
                                <li><Link to="/service/software-development">Software Development</Link></li>
                                <li><Link to="/service/Web-development">Web Development</Link></li>
                                <li><Link to="/service/analytic-solutions">Analytic Solutions</Link></li>
                                <li><Link to="/service/cloud-and-devOps">Cloud and DevOps</Link></li>
                                <li><Link to="/service/recruiting-staffing">Recruiting &amp; Staffing</Link></li>
                                <li><Link to="/service/system-integration">System Integration &amp; Consulting</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 md-mb-30">
                            <h3 className="widget-title">Contact Info</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <a rel="" href="https://www.google.com/maps/place/Bizu+LLC/@39.2657235,-81.5605031,17z/data=!4m8!1m2!2m1!1sBizu+llc+Parkersburg,+WV,+USA!3m4!1s0x88484b13bc61bbf5:0xfee7fb1c2169f89a!8m2!3d39.2657237!4d-81.5583144">
                                        <div className="desc">601 Avery Street, 26101, Parkersburg, WV, USA</div>
                                    </a>
                                    <a rel="" href="https://www.google.com/maps/place/Bizu+LLC/@38.90614502542802,-77.03953889325302,17z/data=!4m8!1m2!2m1!1sBizu+llc+Washington,+DC,+USA!3m4!1s0x88484b13bc61bbf5:0xfee7fb1c2169f89a!8m2!3d38.90614502542802!4d--77.03953889325302">
                                        <div className="desc">1730 Rhode Island Avenue, N.W., Suite 1005, Washington D.C. 20036</div>
                                    </a>
                                </li>
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">
                                        <Link to="#">+1(304)4538750</Link>
                                    </div>
                                    <div>
                                        <Link to="#">+1(202)8492903</Link>
                                    </div>
                                    <div>
                                        <Link to="#">+1(202)8492932</Link>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc">
                                        <Link to="#">support@bizu.llc</Link>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-clock"></i>
                                    <div className="desc">
                                        Opening Hours: 10:00 - 18:00
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBottom />
        </footer>
    );
}

export default Footer;